.research-landing {
  background: url("../../assets/homebackground.jpg");
  height: 100vh;
  padding: 10%;
  .glass-background {
    height: 70vh;
    display: flex;
    align-items: center;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0)
    );
    backdrop-filter: blur (40px);
    -webkit-backdrop-filter: blur (20px);
    box-shadow: 2px 2px 15px -1px rgba(0, 0, 0, 0.25);
  }
}

.research {
  background: url("../../assets/homebackground.jpg");
  height: 100vh;
  padding: 10%;
  .research-searchbar {
    width: 90%;
    background-color: #fff;
  }
  .research-card {
    padding: 2% 2% 0% 8%;
  }
  .css-bhp9pd-MuiPaper-root-MuiCard-root {
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0)
    ) !important;
    backdrop-filter: blur (40px);
    -webkit-backdrop-filter: blur (20px);
    box-shadow: 2px 2px 15px -1px rgba(0, 0, 0, 0.25);
  }
  .cssload-container {
    top: 50%;

    position: relative;
  }

  .cssload-whirlpool,
  .cssload-whirlpool::before,
  .cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    // border: 1px solid rgb(204, 204, 204);
    // border-left-color: rgb(255, 255, 255);
    border: 1px solid blue;
    border-left-color: rgb(255, 255, 255);
    border-radius: 974px;
    -o-border-radius: 974px;
    -ms-border-radius: 974px;
    -webkit-border-radius: 974px;
    -moz-border-radius: 974px;
  }

  .cssload-whirlpool {
    margin: -24px 0 0 -24px;
    height: 49px;
    width: 49px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
  }

  .cssload-whirlpool::before {
    content: "";
    margin: -22px 0 0 -22px;
    height: 43px;
    width: 43px;
    animation: cssload-rotate 1150ms linear infinite;
    -o-animation: cssload-rotate 1150ms linear infinite;
    -ms-animation: cssload-rotate 1150ms linear infinite;
    -webkit-animation: cssload-rotate 1150ms linear infinite;
    -moz-animation: cssload-rotate 1150ms linear infinite;
  }

  .cssload-whirlpool::after {
    content: "";
    margin: -28px 0 0 -28px;
    height: 55px;
    width: 55px;
    animation: cssload-rotate 2300ms linear infinite;
    -o-animation: cssload-rotate 2300ms linear infinite;
    -ms-animation: cssload-rotate 2300ms linear infinite;
    -webkit-animation: cssload-rotate 2300ms linear infinite;
    -moz-animation: cssload-rotate 2300ms linear infinite;
  }

  @keyframes cssload-rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes cssload-rotate {
    100% {
      -o-transform: rotate(360deg);
    }
  }

  @-ms-keyframes cssload-rotate {
    100% {
      -ms-transform: rotate(360deg);
    }
  }

  @-webkit-keyframes cssload-rotate {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @-moz-keyframes cssload-rotate {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
}
