.tabContainer1 {
  padding: 10% 12% 0 12%;

  .webpage,
  .document {
    height: 64vh;
    border-radius: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .webpage {
    .searchContent {
      width: 90%;
      display: flex;
      flex-direction: column;
      /* justify-content: center; */
      align-items: center;

      .searchField {
        width: 100%;
      }
    }

    .searchbar {
      width: 230px;
      display: flex;
      align-items: center;
      gap: 5%;
    }
    .importlink {
      color: #001a5c;
    }
    .scan-btn {
      margin-top: 3%;
      width: 20vw;
    }
    .css-dihucs-MuiContainer-root {
      width: 100%;
      margin-left: auto;
      box-sizing: border-box;
      margin-right: auto;
      display: block;
      padding-left: 16px;
      padding-right: 16px;
    }
    // .css-1tz57tp-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    //   width: auto;
    // }
  }

  .document {
    .insideDropzone {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .MuiDropzoneArea-text {
      margin: 0;
    }

    .MuiDropzoneArea-root {
      width: 587px;
      display: flex;
      justify-content: center;
      min-height: 100px;
      height: 140px;
    }
    .MuiDropzoneArea-textContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
    .uploadBtn {
      margin: 5% 0;
      display: flex;
      justify-content: flex-end;
      gap: 6%;
    }
  }

  .textarea {
    position: relative;
    margin-left: 30%;
    padding: 2%;
    margin-top: 280px;
    border: 2px inset #001a5c;
    background-color: #ffffff;
    width: 500px;
    height: 120px;
    overflow: auto;

    .icon {
      cursor: pointer;

      position: absolute;
      top: 2px;
      right: 2px;
    }
  }
}
