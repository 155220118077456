.doc-generator {
  background: url("../../assets/homebackground.jpg");
  background-repeat: repeat;
  background-size: cover;
  min-height: 100vh;
  padding: 0 5%;
  margin-top: 7%;

  .doc-wrapper {
    padding: 2%;
    background-color: #fff;

    .search-result {
      max-height: 450px;
      overflow-y: auto;
      padding: 2%;
      border-radius: 25px;

      .cards {
        display: flex;
        margin: 1%;
        padding: 1%;
        border: 1px solid rgba(0, 0, 0, 0.24);
        cursor: pointer;

        .page-content {
          color: #000;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.9px;
          margin: 0.2%;
        }
      }
    }

    .search-result-heading {
      margin: 1% 0 1% 0;
      padding: 0.5%;
      border-radius: 0px 0px 10px 10px;
      border: 1px solid rgba(0, 0, 0, 0.24);
      background: #7a7777;
      overflow: hidden;
      color: #fff;
      text-overflow: ellipsis;
      font-family: "Poppins", sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;

      .spantext {
        font-size: 14px;
      }
    }

    .generate-document-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1%;
    }

    .research-searchbar {
      width: 100%;
      background-color: #fff;
    }

    .output {
      overflow: hidden;
      color: #000;
      text-overflow: ellipsis;
      font-family: "Poppins", sans-serif;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.3px;
    }

    .output-result {
      height: 600px;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.24);
      background: #fff;
      overflow-y: auto;
      padding: 1%;
      color: #000;
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.9px;

      .pre-data {
        white-space: pre-wrap;
        font-family: "Poppins", sans-serif;
        font-size: 20px;
        font-weight: 600;
        // line-height: 22px;
      }
    }
  }
}

.MuiList-root {
  background-color: #ffffff;

  .list-item-download {
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 700;
    padding: 15px 30px;
    display: flex;
    gap: 10px;

    img {
      width: 40px;
    }
  }

  .list-item-download:hover {
    background-color: #e0e0e0;
  }
}

.ppt-template-modal {
  background-color: #ffffff;
  // width: 500px;
  // height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding: 2% 3% 3% 3%;
  border-radius: 10px;
  flex-direction: column;
  gap: 30px;

  .modal-heading {
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
  }

  .card-wrap {
    display: flex;
    gap: 50px;

    .template-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      border: 2px solid #001a5c;
      padding: 5px;
      cursor: pointer;
      font-family: "Poppins", sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;

      img {
        width: 250px;
        // height: 100px;
      }
    }

    .template-card:hover {
      transform: scale(1.02);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
