.biobot-history {
  height: 74vh;
  width: 100%;
  border-radius: 15px;
  padding: 5% 8% 5% 0%;

  .historyLoader {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .arrow-img {
    width: 14%;
    cursor: pointer;
    margin-bottom: 7%;
  }

  .heading {
    font-size: 22px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
  }

  .create-btn {
    width: 100%;
    padding: 8%;
    margin: 10% 0 10% 0;
    background: rgba(1, 26, 89, 1);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.18);

    font-family: Arial;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0.02em;
    text-align: left;
  }
  .create-btn:hover {
    backdrop-filter: black;
    box-shadow: 0px 6px 18px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(255, 255, 255, 0.18);
    color: #fff;
  }

  .history-card-box {
    height: 60%;
    overflow: auto;

    .chat-card {
      margin-bottom: 4%;
      padding: 2% 4%;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;

      .chat-card-section-1 {
        display: flex;
        gap: 5%;
        align-items: center;
        width: 100%;
        .session {
          color: #939393;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 0.02em;
          text-align: left;
          letter-spacing: 0.02em;
        }

        .chat-history-box {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .session-text {
            // color: #939393;
            color: #000000;

            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.02em;
            text-align: left;
          }
        }
      }
      .more:hover {
        background-color: gray;
        color: white;
      }
    }
  }
}
