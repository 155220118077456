* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  list-style-type: none;
  list-style: none;

  // --toastify-toast-width: 150px;
}
