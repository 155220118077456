.fdaDraft {
  padding: 8% 2% 0.5% 2%;
  background-image: url("../../assets/homebackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  .css-gll3vz-MuiInputBase-root-MuiOutlinedInput-root {
    background: #ffffff;
  }
  .arrow-btn {
    cursor: pointer;
    width: 40px;
  }
  .heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2vw;
    gap: 1vw;
    &-title {
      font-size: 1.5vw;
      font-weight: 500;
    }
    &-summary {
      font-size: 1vw;
      color: #0074fc;
    }
    &-support {
      margin-top: 1.5vw;
      font-size: 1vw;
      font-weight: 500;
    }
  }
  .upload-section {
    display: flex;
    min-height: 40vh;
    border-radius: 2vw;
    justify-content: center;

    &-dotted {
      padding-top: 3%;
      padding-bottom: 6%;
      width: 60%;
      align-self: center;
      .insideDropzone {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .MuiDropzoneArea-text {
        margin: 0;
      }

      .MuiDropzoneArea-root {
        width: auto;
        display: flex;
        justify-content: center;
        border-radius: 2vw;
        height: 80%;
      }
      .MuiDropzoneArea-textContainer {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
      .uploadBtn {
        margin: 5% 0;
        display: flex;
        justify-content: flex-end;
        gap: 6%;
      }
    }
    .waitResp {
      width: 20vw;
      animation: anim 1s steps(10) infinite;
    }
    .draft-container {
      width: 100%;
      min-height: 50vw;
      display: flex;
      gap: 1%;

      .pdf-section {
        // height: 75vh;
        width: 37%;
        overflow: hidden;
        padding: 2%;

        .pdf-heading {
          margin-bottom: 3.5vh;
        }

        .pdf-viewer {
          height: 98%;
          border-radius: 10ox;
          background-color: white;
          border-radius: 10px;
        }
      }

      .download-section,
      .loader {
        // height: 80vh;
        width: 65%;
        overflow: auto;
        padding: 2%;
        // background-color: white;
        display: flex;
        flex-direction: column;

        .download-btn-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 2vh;

          .dwnd-btn {
            display: flex;
            width: 10vw;
            border: none;
            border-radius: 32px;
            font-weight: bold;
            align-self: end;
            background-color: #001a5c;
            color: white;
            gap: 1vw;
            // height: 2.5vw;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
          }
        }

        .docs {
          background-color: white;
          margin-bottom: 2%;
          padding: 3%;
          // height: 98%;
          border-radius: 10px;
          text-wrap: wrap;
          overflow: auto;
          font-family: "Inter";
        }

        .docs::-webkit-scrollbar {
          display: none;
        }
      }

      .download-section::-webkit-scrollbar {
        display: none;
      }

      .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
      }
    }
  }
}

.MuiDropzoneArea-icon {
  color: #001a5c !important;
}
