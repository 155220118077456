.pdf-section {
  height: 75vh;
  overflow: hidden;
  padding: 2%;
  width: 65%;

  // margin-left: 18%;
  .pdf-heading {
    margin-bottom: 3.5vh;
    font-family: " Open Sans", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: left;
  }

  .pdf-viewer {
    height: 98%;
    border-radius: 10ox;
    background-color: white;
    border-radius: 10px;
  }
}

.btn-div {
  display: flex;
  justify-content: end;
  width: 92%;
  font-size: large;
}

.question-container {
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  // width: 60%;

  // text-align: center;
}

.question-container h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;

  margin-bottom: 20px;
}

.question-container ul {
  list-style: none;
  padding: 0;
}

.question-container li {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;

  margin-bottom: 10px;
}

.correct-option {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #26be00;
  margin-right: 5px;
}

.correct-choice {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
}

.question-container label {
  display: block;
}

.question-container button {
  margin-top: 20px;
}

.docContent {
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: 2% 1%;
  height: 65vh;
  overflow: auto;
  white-space: pre-wrap;
  padding: 1%;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #001a5c;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #001a5c;
}

.cards-section {
  width: 80%;
  margin-top: 8%;
  margin-left: 10%;
  display: flex;
  gap: 45px;
  // margin-top: 40px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card {
  // background-color: #f0f0f0;
  background: linear-gradient(156.41deg, #ffffff 21.84%, #d8eaff 91.45%);
  border: 1px solid #ccc;
  border-radius: 8px;
  gap: 8%;
  padding: 20px;
  cursor: pointer;
  width: 250px;
  transition: background-color 0.3s ease;
  box-shadow: 10px 10px 5px -3px rgba(0, 0, 0, 0.75);
}

.card:hover {
  background: #e0e0e0;
  scale: transform(1.1);
}

.card div {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.container {
  text-align: center;
  margin: 20px;
}

.title {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;

  color: #001a5c;
}

.prompt {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #001a5c;
}

.text-section {
  padding: 20px;
}

.insights-container {
  display: flex;
  flex-direction: column;

  gap: 5px;
  height: 68vh;
  max-height: 70vh;
  overflow-y: auto;
}

.insight-card {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin-bottom: 20px;

  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.insight-card:hover {
  background-color: #e0e0e0;
}

.insight-card p {
  margin: 0;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 20px;
}

.start-test-btn {
  background-color: #001a5c;
  color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.start-test-btn:hover {
  background-color: #001a5c;
}