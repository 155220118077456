.youtube-upload {
  background-image: url("../../assets//tabBG.svg");
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12%;
  margin-left: 8%;
  margin-right: 8%;

  height: 70vh;
  padding: 4% 4.5%;
  border-radius: 37px;
  gap: 9%;
  .text {
    text-align: center;
  }
  .new-video {
    padding: 1%;
    width: 25%;
  }
}
