.navbar {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 3%;
  z-index: 100;
  width: 100%;
  height: 100px;
  backdrop-filter: blur(27px);
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.38) 0%,
    rgba(255, 255, 255, 0.71) 100%
  );

  box-shadow: 0px 6px 18px 0px #00000040;

  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 8.37);

  .leftSection {
    display: flex;
    gap: 5%;
    .pbResponseAILogo {
      cursor: pointer;
      height: 70px;
    }
  }
  .rightSection {
    display: flex;
    gap: 4%;
    .signUp,
    .signIn {
      background: #001a5c;
      color: #ffffff;
      width: 150px;
      height: 42px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
