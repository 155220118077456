.landing-page {
  video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .video-wrapper {
    // border: 2px solid #000;
    // width: 400px;
    // height: 200px;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .header {
    position: relative;
    width: 100%;
  }
  .landing-page-1 {
    // background: url("../../assets/homebackground.png");
    // background: url("../../assets/background-video.mp4");
    background: linear-gradient(180deg, rgba(0, 116, 252, 0) 0%, #004596 100%);

    margin: 0;
    // margin-top: 100px;
    padding: 6% 5% 0 5%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: start;
    .text-container {
      color: #001a5c;
      padding: 5%;

      .heading {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        // font-size: 36px;
        // line-height: 54px;
        // letter-spacing: 0em;
        text-align: left;

        color: #fff;
        font-family: "Poppins", sans-serif;
        // font-size: 90px;
        font-size: 5vw;
        font-weight: 700;
        // line-height: 100px;
      }

      // @media only screen and (min-width: 1000px) {
      //   .heading {
      //     font-size: 60px;
      //   }
      // }
      // @media only screen and (min-width: 1200px) {
      //   .heading {
      //     font-size: 90px;
      //   }
      // }

      .pera-1 {
        // font-size: 26px;
        margin: 1% 0;
        // line-height: 34px;
        // letter-spacing: 0em;
        text-align: left;

        color: #fff;
        font-family: "Poppins", sans-serif;
        font-size: 2vw;
        font-weight: 300;
        // line-height: 50px;
      }

      .pera-2 {
        // color: #0074fc;
        // font-size: 18px;
        text-align: left;
        margin: 4% 0 1% 0;

        color: #fff;
        font-family: "Poppins", sans-serif;
        font-size: 1.5vw;
        font-weight: 500;
      }
      .desc {
        text-align: justify;
        color: #fff;
        font-family: "Poppins", sans-serif;
        font-size: 1vw;
        font-style: normal;
        font-weight: 400;
        // line-height: 30px;
        width: 70%;
      }
      .learn-btn {
        font-size: 16px;
        font-weight: 600;
        // line-height: 24px;
        padding: 2.2% 3.5%;
        letter-spacing: 0em;
        text-align: left;
        border-radius: 5px;
        box-shadow: 0px 6px 18px 0px #00000040;
        margin: 5% 0;
      }
    }
  }
  .landing-page-part2 {
    padding: 3% 5%;
    gap: 5.5%;
    background: url("../../assets/Group\ 2558.png");
    background-size: cover;
    // height: 100vh;
    display: flex;
    align-items: center;
    .section-2-img {
      margin-bottom: 4%;
      img {
        width: 37vw;
      }
    }
    .textcontainer-part2 {
      // padding-left: 5%;
      .section2-text1 {
        font-size: 2vw;
        font-weight: 500;
        // line-height: 34px;
        // letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 26, 92, 1);
      }
      .section2-text2 {
        font-size: 1.2vw;
        font-weight: 400;
        // line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 26, 92, 1);
        margin: 2% 0;
      }
    }

    .text-grid {
      color: rgba(0, 26, 92, 1);
      text-align: left;
      font-size: 0.9vw;
      font-weight: 400;
      width: 80%;
    }
  }

  .landing-page-part3 {
    background: linear-gradient(
      180deg,
      rgba(178, 198, 205, 0) 0%,
      #b1c5cc 76.56%
    );

    padding: 0 5%;
    // height: 75vh;
    display: flex;
    gap: 10%;
    align-items: center;
    justify-content: center;

    .section-3-img {
      margin-bottom: 4%;
      img {
        width: 32vw;
      }
    }
    .text-container {
      text-align: left;

      .section-3-text-1 {
        font-size: 1.5vw;
        font-weight: 500;
        // line-height: 34px;
        // letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 26, 92, 1);
      }
      .section-3-text-2 {
        font-size: 1vw;
        // font-weight: bold;
        // line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        margin: 4% 0;
      }
      .li-tags {
        list-style-type: disc;
        list-style-position: outside;
        list-style-type: initial;
        font-size: xx-large;
      }
      .section-3-text-3 {
        font-size: 1.5vw;
        font-weight: 700;
        // line-height: 22px;
        letter-spacing: 0em;
        margin: 4% 0;

        text-align: left;
      }

      .desc {
        text-align: justify;
        color: #928e8e;
      }
    }
  }
  .footer {
    background: #011956;
    text-align: center;
    padding: 2% 5%;
    color: #ffff;
    display: flex;
    flex-direction: column;
    .footer-bottom {
      display: flex;
      justify-content: space-between;
      .copyright {
        // cursor: pointer;
        color: #ffffff;
        .seperator {
          color: white;
        }
      }
      .copyright-condition {
        // color: #58ff9e;
        span:hover:nth-child(1) {
          text-decoration: underline;
          color: #58ff9e;
        }
        span:hover:nth-child(3) {
          text-decoration: underline;
          color: #58ff9e;
        }
        .policy {
          cursor: pointer;
        }
        .line-spacing {
          cursor: crosshair;
        }
      }
    }
  }
}
