.voice-chat {
  height: 57vh;
  padding: 2%;
  .chat-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0% 4% 0% 4%;
    .quest-ans ::-webkit-scrollbar {
      display: none;
    }
    .quest-ans {
      height: 39vh;
      width: fit-content;
      background-color: #ffffff;
      display: flex;
      // flex-direction: column;
      align-items: flex-end;
      border-radius: 15px 15px 0 0;
      overflow: hidden;

      // overflow-y: auto;
      .feed {
        padding: 2% 4%;
        display: flex;
        width: 60vw;
        flex-direction: column;
        overflow-y: scroll;
        height: 100%;
      }
      .chat-quest {
        display: flex;
        width: 59vw;
        gap: 0.4vw;
        align-self: flex-end;
        justify-content: end;
        .question {
          max-width: 56%;
          display: flex;
          background-color: #efefef;
          text-align: justify;
          align-items: center;
          float: right;
          color: black;
          padding: 14px 2vw;
          letter-spacing: 0.02em;
          margin-bottom: 1%;
          border-radius: 15px 0px 15px 15px;
          min-height: 30px;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
        }

        .userIcon {
          font-size: 2vw;
          width: 3vw;
        }
      }
      .chat-answ {
        display: flex;
        width: 100%;
        gap: 0.4vw;
        align-items: flex-start;
        .answer {
          max-width: 56%;
          display: flex;
          background: /* linear-gradient(0deg, #ffffff, #ffffff) */ #d0e7ff;
          border: 1px solid #001a5c;
          text-align: justify;
          align-items: center;
          float: right;
          color: #000000;
          padding: 14px 2vw;
          margin-bottom: 1%;
          padding: 0.4vw;
          border-radius: 0px 15px 15px 15px;
          min-height: 30px;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
          text-align: left;
          .isTyping-gif {
            min-height: 1vw;
            max-height: 2vw;
            width: 3vw;
          }
          div {
            span {
              span {
                display: none !important;
              }
            }
          }
        }
        .botIcon {
          width: 3vw;
          height: 3vw;
        }
      }
    }
    .search-container {
      // position: absolute;
      // bottom: -10%;
      width: 60vw;
      background-color: #ffffff;
      padding: 1% 4%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14px;
      border-radius: 0 0 15px 15px;
      .search-box {
        display: flex;
        align-items: center;
        width: 100%;
        border-style: solid;
        border-radius: 7px;
        border-width: thin;
        height: fit-content;
        border: 2px solid #001a5c;
        padding-right: 5px;
        .microphone {
          align-self: center;
          margin-right: 0.3vw;
          font-size: 1.5vw;
        }
        .speaking-video {
          height: 4vw;
          width: 4vw;
        }
        .input-box {
          height: 2.7vw;
          border-radius: 10vw;
          border: none;
          padding: 24px;
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0em;
        }
      }
      .listeningIcon {
        width: 84px;
        background-color: transparent;
      }
      .speechIcon {
        cursor: pointer;
        width: 10%;
      }
    }
  }
  .loading,
  .loadingBottom {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  // .loadingBottom {
  //   margin: 27px 0;
  //   font-weight: 900;
  //   gap: 12px;
  //   gap: 16px;
  //   margin: 27px 0;
  // }
}
