.terms-container {
  height: auto;
  word-wrap: break-word;
  width: auto;
  margin-top: 6.5vw;
  padding-bottom: 2vw;
  margin-left: auto;
  margin-right: auto;

  .heading {
    text-decoration: underline;
    text-decoration-color: #46e187;
    text-underline-position: under;
    text-align: center;
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 2rem;
    color: #153359;
    padding-bottom: 3vw;
  }
  .content-container {
    margin: 0vw 8vw 0vw 8vw;
    text-align: justify;
    .description {
      font-size: 1rem;
      margin-bottom: 2vw;
    }
    .content-header {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 1.6em;
    }
    ol {
      list-style: lower-roman;
    }
  }
}

@media only screen and (max-width: 820px) {
  .terms-container {
    padding-top: 6vw;
    .heading {
      font-size: 1.5rem;
    }
  }
}
@media only screen and (max-width: 480px) {
  .terms-container {
    padding-top: 5vw;
    word-wrap: break-word;
    width: 100vw;
    .heading {
      padding-top: 4vw;
    }
  }
}
