.home {
  background-image: url("../../assets/homebackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 97vh;
  width: 100vw;

  margin: 3vh 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .center-content {
    // background-image: url("../../assets/homeBgImg.svg");
    padding: 2% 4%;
    width: 75vw;
    height: 70vh;
    border-radius: 44px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 9%;
    align-items: center;
    font-weight: 400;
  }

  .text {
    // font-size: 13px;
    // font-weight: 600;
    // line-height: 19px;
    // letter-spacing: 0em;
    // text-align: center;

    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #001a5c;
  }

  .pbbotdesc {
    width: 12%;
  }

  .otherBtnBox {
    display: flex;
    justify-content: space-between;
    gap: 7%;
  }

  .explore-btn,
  .btn-2 {
    width: 300px;
    height: 60px;
    color: #001a5c;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    &:hover {
      background: #ffffffcc;
    }
  }

  .explore-btn {
    border-radius: 5x;
    font-size: 20px;
    background: radial-gradient(
      97.57% 210.75% at 0.9% 2.98%,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    box-shadow: 0px 6px 18px 0px #00000040;
  }
  .btn-2 {
    display: flex;
    justify-content: center;
    gap: 5%;
    border-radius: 5px;
    background: #ffffffcc;
    font-size: 15px;
    text-align: center;
  }
  .btn-2-text {
    text-align: left;
  }
}
