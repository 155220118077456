.progress-wrap {
  background-color: #ffffff;
  width: 500px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding: 5%;
  border-radius: 10px;
  flex-direction: column;
  gap: 30px;
}
